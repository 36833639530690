/*======================================
9. About Page
 =======================================*/

/* 9.1 wpo-mission-vission-section */

.wpo-mission-vission-section{
  .wpo-mission-item,
  .wpo-vission-item{
      padding: 50px;
      background: $dark-gray2;
      border-radius: 10px;
      position: relative;
      z-index: 1;
      overflow: hidden;

      @media(max-width:575px){
         padding: 20px;
      }

      .shape-1{
        position: absolute;
        left: -70px;
        bottom: -70px;
        width: 200px;
        height: 200px;
        background: $theme-primary-color;
        border-radius: 50%;
        z-index: -1;
      }

      .shape-2{
          position: absolute;
          right: 0;
          top: 0;
      }

      .wpo-mission-text,
      .wpo-vission-text{
          h2{
              color: $theme-primary-color;
              font-size: 34px;
              margin-bottom: 20px;
          }

          p{
              color: $light;
              font-size: 15px;
              margin-bottom: 20px;
          }

          h5{
              color: $white;
              font-size: 18px;
              line-height: 27px;
          }
      }
  }

  .wpo-vission-item{
    background-color: $section-bg-color-s2;

    @media(max-width:991px){
     margin-top: 30px;
    }
    .wpo-vission-text{
        p{
            color: #616161;
            margin-bottom: 0;
        }

        h5{
            color: $dark-gray2;
            margin-bottom: 20px;

        }
    }
  }
}


/* 9.2 wpo-about-section-s4 */

.wpo-about-section-s4{
    position: relative;
    overflow: hidden;

    @media(max-width:991px){
       padding-top: 0;
    }
    .wpo-about-inner{
        box-shadow: none;
        padding: 0;
        margin-top: 0;

        .wpo-about-text{
            padding-left: 0;
            @media(max-width:991px){
                padding-top: 0;
                margin-bottom: 40px;
             }
        }
    }

    .shape-ab{
        position: absolute;
        right: -150px;
        bottom: -180px;
        width: 330px;
        height: 330px;
        background: $theme-primary-color;
        border-radius: 40px;
        transform: rotate(20deg);
        z-index: 110;

        @media(max-width:767px){
            right: -50px;
            bottom: -80px;
            width: 200px;
            height: 200px;
        }
        @media(max-width:575px){
            right: -50px;
            bottom: -80px;
            width: 160px;
            height: 160px;
        }

        &:before{
            position: absolute;
            left: -30px;
            top: 30px;
            width: 100%;
            height: 100%;
            border: 1px solid $dark-gray2;
            content: "";
            border-radius: 40px;
        }

    }

    .wpo-about-wrap{
        .wpo-about-img{
            max-width: 100%;
        }
    }
}

///////////////clases maik

.maintext{
align-self: start!important;
}

.maintexttop{
margin: 0!important;
margin-bottom: 30px!important;
        @media(max-width:1199px) and (min-width: 992px){
            margin-top: 150px!important;
        }

}

.wpo-about-text ul li{
    margin-bottom: 3px;
}

.wpo-about-text ul .firstitem{
    // color: red!important;
    list-style: none!important;
    text-decoration: underline;
    font-weight: bold;
}

.leftrow{
float: left;
list-style: square;
}

.rightrow{
float: right;
list-style: square;

@media (max-width: 405px) {
    float: left;
    }

}

.respAbout{
    @media(max-width: 1199px) and (min-width: 992px){
        width: 10rem;

    }

    @media(max-width: 520px){
        width: 12rem;

    }
}

.leftrow.respAbout{

        @media(max-width: 576px){
            margin-left: 23px;
        }

        @media(max-width: 405px){
            width: 100%;
            margin-bottom: 15px;
        }
    
}

.rightrow.respAbout{

    @media(max-width: 405px){
        margin-left: 23px;
        width: 100%;
        
    }

}

.imgholder{
    @media(max-width:900px){
        padding-left: 0px;
        padding-right: 0px;

    }
}