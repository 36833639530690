/*=====================================================
12. wpo-project-single
======================================================*/
.wpo-project-single-area {
    .wpo-project-single-wrap {
        .wpo-project-single-title {
            h3 {
                font-weight: 500;
                font-size: 35px;
                line-height: 130.5%;
                margin-bottom: 20px;

                @media(max-width:767px) {
                    font-size: 25px;
                }
            }
        }

        .wpo-project-single-item {
            margin-bottom: 50px;
            &:last-child{
                margin-bottom: 0;
            }

            .wpo-project-single-main-img {
                margin-bottom: 50px;
                margin-top: 20px;

                .owl-controls {
                    width: 100%;
                    margin: 0;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    @include translatingY();
                }

                .owl-nav [class*=owl-]:hover {
                    background: $theme-primary-color;
                    color: $white;
                }

                .owl-nav [class*=owl-] {
                    background: $white;
                    width: 50px;
                    height: 50px;
                    line-height: 58px;
                    padding: 0;
                    margin: 0;
                    border-radius: 50%;
                    color: $theme-primary-color;
                    @include transition-time(0.3s);
                    border: 0;

                    .fi {
                        &::before {
                            font-size: 20px;
                        }
                    }
                }

                .owl-nav .owl-prev,
                .owl-nav .owl-next {
                    position: absolute;
                    top: 50%;
                    @include translatingY();
                }

                .owl-nav .owl-prev {
                    left: 15px;
                }

                .owl-nav .owl-next {
                    right: 15px;
                }
            }

            .wpo-project-single-content-des-right {
                max-width: 380px;
                flex-basis: 35%;
                margin-left: auto;
                margin-bottom: 50px;

                @media(max-width:1200px) {
                    flex-basis: 100%;
                    margin-bottom: 40px;
                }

                @media(max-width:991px) {
                    margin-left: 0;
                    max-width: 100%;
                    margin-top: 20px;
                }

                ul {
                    list-style: none;
                    padding: 50px 30px;
                    box-shadow: 0px 2px 10px rgba(14, 29, 44, 0.15);

                    @media(max-width:575px) {
                        padding: 50px 20px;
                    }

                    li {
                        display: flex;
                        padding: 10px 0;
                        font-size: 16px;
                        color: $dark-gray;
                        font-weight: 600;
                        position: relative;
                        justify-content: space-between;
                        font-weight: 600;

                        span {
                            flex-basis: 60%;
                            color: $body-color;
                            font-weight: 400;
                        }

                        &:last-child {
                            padding-bottom: 0;
                            border-bottom: 0;
                        }

                        &:first-child {
                            padding-top: 0;
                        }
                    }
                }
            }

        }

        .list-widget {
            ul {
                list-style: none;

                li {
                    padding: 7px 0;
                    padding-left: 35px;
                    position: relative;

                    &:before {
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 25px;
                        height: 25px;
                        line-height: 25px;
                        border-radius: 50%;
                        background: transparentize($theme-primary-color, .9);
                        content: "\e64c";
                        font-family: "themify";
                        text-align: center;
                        color: $theme-primary-color;
                        font-size: 14px;
                    }
                }
            }
        }

        .wpo-p-details-img {
            margin-bottom: 10px;
        }

        .wpo-project-single-item-quote {
            padding: 45px;
            background: transparentize($theme-primary-color, .9);
            text-align: center;
            border-radius: 15px;

            @media(max-width:991px) {
                padding: 20px;
                margin-top: 30px;
            }

            p {
                font-size: 22px;
                margin-bottom: 30px;
                color: $dark-gray;
                line-height: 44px;

                @media(max-width:991px) {
                    font-size: 16px;
                    margin-bottom: 20px;
                }
            }

            span {
                font-size: 22px;
                font-weight: 600;

                @media(max-width:991px) {
                    font-size: 16px;
                }

                span {
                    color: $theme-primary-color;
                    font-weight: 400;
                }
            }
        }

        .list-widget-s {
            @media(max-width:991px) {
                margin-top: 30px;
            }
        }

        .wpo-project-single-gallery {
            margin-bottom: 30px;
        }

        .wpo-project-contact-area {
            padding: 30px;
            border-radius: 20px;
            box-shadow: 0px 2px 8px 2px rgb(22, 14, 71, .10);

            .wpo-contact-title {
                margin-bottom: 30px;
            }

            // .wpo-contact-form-area {
            //     .col {
            //         margin-bottom: 30px;
            //     }

            //     .form-control {
            //         height: 50px;
            //         border: 0;
            //         border-bottom: 1px solid $border-color;
            //         border-radius: 0;
            //         background: none;

            //         &:focus {
            //             outline: none;
            //             box-shadow: none;
            //         }
            //     }

            //     textarea.form-control {
            //         height: 120px;
            //     }

            //     select.form-control {
            //         background: transparent url(../../images/select-icon2.png) no-repeat calc(100% - 15px) center;
            //     }

            //     .submit-area {
            //         button {
            //             border-radius: 6px;
            //         }
            //     }

            //     .errorMessage {
            //         color: red;
            //         margin-top: 5px;
            //     }
            // }
        }

        .related-project {
            .wpo-project-section-s4 {
                padding-bottom: 0;

                .wpo-project-container {
                    padding: 0;
                }
            }
        }
    }
}

/////////////////////////estilos custum maik
.datos {
    white-space: pre-line;
    text-align: justify;
}

.firstPar{
    width: 100%!important;
}


//////////////////////
/// estilos contact form

.wpo-contact-form-area {
    // padding: 50px;
    // background: $white;
    // box-shadow: 0px 1px 15px 0px rgba(62, 65, 159, 0.1);
    // padding-bottom: 40px;
    // margin-bottom: -125px;
    // position: relative;
    // z-index: 99;

    // @media(max-width:767px) {
    //     padding: 30px;
    //     padding-top: 50px;
    // }
}


// .wpo-contact-map-section {
// 	.wpo-contact-map {
// 		height: 550px;
// 	}

// 	iframe {
// 		width: 100%;
// 		height: 100%;
// 		border: 0;
// 		outline: 0;
// 	}

// 	h2.hidden {
// 		display: none;
// 	}
// }

// .wpo-contact-title {
//     max-width: 440px;
//     margin: 0 auto;
//     text-align: center;
//     margin-bottom: 50px;

//     @media(max-width:767px) {
//         margin-bottom: 30px;
//     }

//     h2 {
//         font-size: 35px;
//         font-size: calc-rem-value(35);
//         font-weight: 700;
//         margin-bottom: 20px;

//         @media(max-width:575px) {
//             font-size: 25px;
//             font-size: calc-rem-value(25);
//         }
//     }
// }

form input,
form select,
form textarea {
    background: transparent;
    width: 100%;
    height: 50px;
    // border: 1px solid transparent;
    border-radius: 0px;
    box-shadow: none !important;
    padding-left: 25px;
    border: 1px solid #ebebeb;

    @include media-query(991px) {
        height: 45px;
    }

    &:focus {
        border-color: $theme-primary-color;
        background: transparent;
    }
}

form textarea {
    height: 180px;
    padding-top: 15px;
}

form {
    margin: 0 -15px;
    overflow: hidden;

    @include placeholder-style(#9d9c9c, 15px, normal);

    select {
        display: inline-block;
        color: #a9a9a9;
        cursor: pointer;
        opacity: 1;
        padding: 6px 25px;
        font-size: 15px;
        font-size: calc-rem-value(15);
        -webkit-appearance: none;
        -ms-appearance: none;
        -o-appearance: none;
        appearance: none;
        -moz-appearance: none;
        background: transparent url(../../images/select-icon2.png) no-repeat calc(100% - 15px) center;
        position: relative;

        &:focus {
            background: transparent url(../../images/select-icon2.png) no-repeat calc(100% - 15px) center;
        }
    }

    .submit-area {
        text-align: center;
        width: 100%;
        margin-bottom: 10px;
        margin-left: 0;

        @include media-query(767px) {
            margin-bottom: 0;
        }

        .theme-btn {
            border-radius: 0px;
            font-family: $base-font;
            font-size: 16px;

            &:after {
                border-radius: 0px;
            }
        }
    }

    .form-field{
        margin-bottom: 30px;
    }
    .errorMessage{
        color: red;
        margin-top: 5px;
    }
}

form>div {
    margin: 0 15px 25px;

    @include media-query(600px) {
        float: none;
    }
}

// .office-info {
//     padding-bottom: 100px;
//     padding-top: 15rem;

//     @media(max-width:767px) {
//         padding-bottom: 60px;
//     }

//     .col {

//         .office-info-item {
//             @media(max-width:1200px) {
//                 margin-bottom: 30px;
//             }
//         }

//         &:last-child {
//             .office-info-item {
//                 @media(max-width:1200px) {
//                     margin-bottom: 0px;
//                 }
//             }
//         }

//     }

//     .office-info-item {
//         text-align: center;
//         box-shadow: 0px 5px 15px 0px rgba(62, 65, 159, 0.1);
//         padding: 40px;

//         .office-info-icon {
//             width: 85px;
//             height: 85px;
//             background: #f5f5f5;
//             line-height: 85px;
//             text-align: center;
//             margin: 0 auto;
//             @include rounded-border(50%);

//             .icon {
//                 .fi {
//                     &:before {
//                         font-size: 35px;
//                         line-height: unset;
//                     }
//                 }
//             }
//         }

//         .office-info-text {
//             h2 {
//                 font-size: 30px;
//                 font-weight: 500;
//                 margin: 20px 0;
//             }

//             p {
//                 margin-bottom: 0;
//             }
//         }
//     }

// }

// .wpo-contact-form-area {
//     padding: 50px;
//     background: $white;
//     box-shadow: 0px 1px 15px 0px rgba(62, 65, 159, 0.1);
//     padding-bottom: 40px;
//     margin-bottom: -125px;
//     position: relative;
//     z-index: 99;

//     @media(max-width:767px) {
//         padding: 30px;
//         padding-top: 50px;
//     }
// }



// .wpo-project-single-area .wpo-project-single-wrap .list-widget ul {
//         // column-count: 2;
//         // column-gap: 20px;
//         column-count: 2;
//         column-width: 300px;
//         column-fill: auto;
// }

.serviceResp{
    @media (max-width: 1400px) {
        width: 12rem;
    }

    @media (max-width: 991px) {
        width: auto;
    }

    @media (max-width: 463px) {
        width: 12rem;
    }
    @media (max-width: 383px) {
        width: auto;

        }
    }

